@keyframes letter-animation {
  0% { opacity: 0; transform: translateY(-10px); }
  100% { opacity: 1; transform: translateY(0); }
}

.animate-letter {
  opacity: 0;
  animation: letter-animation 0.5s forwards;
}

/* Fade-in Animation for Second Text */
@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-in-text {
  opacity: 0;
  animation: fade-in 1s forwards;
  animation-delay: 2s; /* Adjust delay to start after the first text animation */
}
@keyframes subtleShake {
  0% { transform: translateX(0) rotate(0deg); }
  10% { transform: translateX(-5px) rotate(-2deg); } /* Slightly rotate left */
  20% { transform: translateX(5px) rotate(2deg); }   /* Slightly rotate right */
  30% { transform: translateX(-3px) rotate(-1deg); } /* Less rotation left */
  40% { transform: translateX(3px) rotate(1deg); }   /* Less rotation right */
  50% { transform: translateX(-2px); }                /* Small left move */
  60% { transform: translateX(2px); }                 /* Small right move */
  70% { transform: translateX(-1px); }                /* Even smaller left move */
  80% { transform: translateX(1px); }                 /* Even smaller right move */
  90% { transform: translateX(0); }                   /* Return to center */
  100% { transform: translateX(0); }                  /* End at center */
}

.shake-subtle-image {
  animation: subtleShake 2s ease-in-out infinite; /* Adjust duration as needed */
}
